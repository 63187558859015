import {createRouter, createWebHistory} from 'vue-router'
import {ElMessage} from "element-plus";


const routes = [
    // 手机号登录
    {
        path: '/',
        name: 'login',
        component: () => import('../views/Login/Login.vue'),
        meta: {
            title: '登录-退役军人优待证管理系统'
        }
    },
    // 注册登录
    {
        path: '/Register',
        name: 'Register',
        component: () => import('../views/Register/Register.vue'),
        meta: {
            title: '注册-退役军人优待证管理系统'
        }
    },
    //
    {
        path: '/LayOut',
        name: 'LayOut',
        component: () => import('../views/LayOut/LayOut.vue'),

        // 二级路由
        children: [
            // 政策管理
            {
                path: '/policy-management',
                name: 'policy-management',
                component: () => import('../views/LayOut/GeneralMessage/GeneralMessage.vue'),
                meta: {
                    title: '政策管理-退役军人优待证管理系统',
                    keepAlive: true //需要被缓存
                }
            },
            // 用户管理
            {
                path: '/customer-management',
                name: 'abc',
                component: () => import('../views/LayOut/UserMessage/UserMessage.vue'),
                meta: {
                    title: '用户管理-退役军人优待证管理系统',
                    keepAlive: true //需要被缓存
                }
            },
            // 资源管理
            {
                path: '/resource-management',
                name: 'resource-management',
                component: () => import('../views/LayOut/ResourceMessage/ResourceMessage.vue'),
                meta: {
                    title: '资源管理-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }

            },
            // 账号管理
            {
                path: '/staff-account-management',
                name: 'taff-account-management',
                component: () => import('../views/LayOut/SystemMessage/StaffMeansssage/StaffMessage.vue'),
                meta: {
                    title: '账号管理-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            // 区域管理
            {
                path: '/area-management',
                name: 'area-management',
                component: () => import('../views/LayOut/SystemMessage/AreaMessage/AreaMessage.vue'),
                meta: {
                    title: '区域管理-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/merchant-management',
                name: 'merchant-management',
                component: () => import('../views/LayOut/MerchantMwssage/MerchantMessage.vue'),
                meta: {
                    title: '商家管理-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/data-overview',
                name: 'data-overview',
                component: () => import('../views/LayOut/DataOverview/DataOverview.vue'),

                meta: {
                    title: '数据概览-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/circle-management',
                name: 'circle-management',
                component: () => import('../views/LayOut/CircleMesage/CircleMessage.vue'),
                meta: {
                    title: '圈子管理-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/circlecom-management',
                name: 'circlecom-management',
                component: () => import('../views/LayOut/CircleMesage/circleCom/circleCom.vue'),
                meta: {
                    title: '圈子评论-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/comment-management',
                name: 'comment-management',
                component: () => import('../views/LayOut/CommentMessage/commentMessage.vue'),
                meta: {
                    title: '资源评论-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/userSuggestion-management',
                name: 'userSuggestion-management',
                component: () => import('../views/LayOut/userSuggestion/userSuggestion.vue'),
                meta: {
                    title: '用户建议-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/sponsorship-management',
                name: 'sponsorship-management',
                component: () => import('../views/LayOut/sponsorship/sponsorship.vue'),
                meta: {
                    title: '赞助管理-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
            {
                path: '/user-greement',
                name: 'user-greement',
                component: () => import('../views/LayOut/ServiceManagement/ServiceManagement'),
                meta: {
                    title: '用户协议-退役军人优待证管理系统', keepAlive: true //需要被缓存
                }
            },
        ]
    },
    {
        path: '/zhengce/:id?',
        name: 'zhengce',
        component: () => import('../views/dialog/zhengcw/dislog.vue'),
        meta: {
            title: '政策标题-退役军人优待证管理系统'
        }
    },
    {
        path: '/ziyuan',
        name: 'ziyuan',
        component: () => import('../views/dialog/ziyuan/ziyuan.vue'),
        meta: {
            title: '资源详情-退役军人优待证管理系统'
        }
    },
]

//路由规则
const router = createRouter({
    mode: 'hash',
    history: createWebHistory(process.env.BASE_URL),
    routes
})
// 默认网页标签
const defaultTitle = '退役军人优待证管理系统';

// 路由拦截
router.beforeEach((to, from, next) => {
    // 动态网页标签
    document.title = to.meta.title ? to.meta.title : defaultTitle;
    // 判断是访问登陆页，有 Token 就在当前页面，没有 Token 重置路由并放行到登陆页
    let storedData = JSON.parse(localStorage.getItem('adminUser')) ? JSON.parse(localStorage.getItem('adminUser')) : '';
    if (storedData) {
        const currentTime = new Date().getTime();
        if (currentTime > storedData.time) {
            // 数据已过期，清除
            localStorage.removeItem('adminUser');
            storedData = null;
        }
    }

    if (to.path === '/') {
        next();
    } else {
        // 获取 token
        const token = JSON.parse(localStorage.getItem('adminUser'));
        // token 不存在
        if (token === null || token === '') {
            ElMessage.error('您还未登录，请先登录');
            next('/');
        } else {
            next();
        }
    }
});
export default router
