import {createStore} from 'vuex'

export default createStore({
    id: "GlobalState",
    state: {
        token: JSON.parse(localStorage.getItem('adminUser')) ? JSON.parse(localStorage.getItem('adminUser')).adminUser.token : ''
    },
    getters: {},
    mutations: {
        setUserToken(state, res) {
            state.token = JSON.parse(res).adminUser.token
        }
    },
    actions: {},
    modules: {}
})
