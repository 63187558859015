import {createApp} from 'vue'
// elemment  UI 框架
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'


import App from './App.vue'
import router from './router'
import store from './store'
// 中文语言包 elementPlus
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

// icon图标
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 屏蔽错误信息
app.config.errorHandler = () => null;
// 屏蔽警告信息
app.config.warnHandler = () => null;

app.use(ElementPlus, {
    locale: zhCn,
}).use(store).use(router).mount('#app')

// axios挂载原型
// app.config.globalProperties.$axios = axios;
