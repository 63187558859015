<template>
  <router-view/>
</template>

<style lang="scss">
@import "./assets/css/reset.css";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #ececec;
  height: 100%;
  width: 100%;
  position: relative;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.test {
  height: 16px;
  line-height: 16px;
}

.edit,
.del {
  padding-right: 10px;
}

.edit {
  cursor: pointer;
  color: #1e90ff;
}

.del {
  color: #d52921;
  cursor: pointer;
}
</style>
